import React from 'react'
import HungryFlow from './HungryFlow/HungryFlow'
import NotHungryFlow from './NotHungryFlow/NotHungryFlow';
import MultiQustion from '../../common/MultiQustion';
import { Redirect } from 'react-router-dom';
import "./tooltip.css"
import "./selectStyles.css"

function BaseFlow(props) {

    let {multiSelectAnswers, directAnswer, nextQuestion, endQuiz, backToLastQuestion, 
        currentQuestion, selectQuestion, onInputChange, answer, quizEnded, queryList}=props
    if (quizEnded) {
        return (
            <Redirect to={{
                pathname: '/quiz/preresults',

                queryList: queryList
            }}
            />
        )

    } else if (currentQuestion === "How many people?") {


        // let howManyPeopleToolTip=`How many people will be involved with what your deciding to do? 
        // If you are alone select 1. Otherwise select the number of people in your group. Dont worry if the number changes, you can always take the quiz again!`

        let howManyPeopleToolTip=`How many people will be involved with what your deciding to do? 
        If you are alone select 1. Otherwise select the number of people in your group.`

        return (
            <div className="dropdownQuestionDiv">



                <h1>How many people?</h1>
                <label className="tool tooltiplabel" data-tip={howManyPeopleToolTip} >More Context</label>
                <div className='selectDiv'>
                    <select name="answer" onChange={onInputChange} value={answer}>
                        <option key="1" value="1">1</option>
                        <option key="2" value="2">2</option>
                        <option key="3" value="3">3</option>
                        <option key="4" value="4">4</option>
                        <option key="5" value="5">5</option>
                        <option key="6" value="6">6</option>
                        <option key="7" value="7">7</option>
                        <option key="8" value="8">8</option>
                        <option key="9" value="9">9</option>
                        <option key="tenPlusPeople" value="tenPlusPeople">10 or More</option>
                    </select>
                </div>


                <button className="btn btn-primary" onClick={() => {
                    selectQuestion();
                    nextQuestion("How Much Time Do you have?")
                }}>NEXT</button>
            </div>
        )

    } else if (currentQuestion === "How Much Time Do you have?") {

        return (

            <MultiQustion
                question={"How Much Time Do you have?"}

                tool_tip_content={`How much time do you have for
                 an activity?`}

                answer_1={"30 minutes or less"}
                onclick_1={() => {
                    directAnswer("30minutesOrLess");
                    nextQuestion("Are you hungry?")
                }}

                answer_2={"1 hour or less"}
                onclick_2={() => {
                    directAnswer("1hourOrLess");
                    nextQuestion("Are you hungry?")
                }}

                answer_3={"2 hours or less"}
                onclick_3={() => {
                    directAnswer("2hoursOrLess");
                    nextQuestion("Are you hungry?")
                }}

                answer_4={"more then 2 hours"}
                onclick_4={() => {
                    directAnswer("2hoursOrMore");
                    nextQuestion("Are you hungry?")
                }}

                backToLastQuestion={backToLastQuestion}
            />

            // <div className="dropdownQuestionDiv">

            //     <h1>How Much Time Do you have?</h1>
            //     <div className='selectDiv'>
            //         <select name="answer" onChange={onInputChange} value={answer}>
            //             <option key="30minutesOrLess" value="30minutesOrLess">30 minutes or less</option>
            //             <option key="1hourOrLess" value="1hourOrLess">1 hour or less</option>
            //             <option key="2hoursOrLess" value="2hoursOrLess">2 hours or less</option>
            //             <option key="2hoursOrMore" value="2hoursOrMore">more then 2 hours</option>
            //         </select>
            //     </div>

            //     <button className="btn btn-primary" onClick={() => {
            //         selectQuestion();
            //         nextQuestion("Are you hungry?")
            //     }}>NEXT</button>

            //     <button className="btn btn-secondary goBackBtn" onClick={backToLastQuestion}>Go Back</button>
            // </div>
        )
    } else if (currentQuestion === "Are you hungry?") {
        return (
            <MultiQustion
                question={"Are you hungry?"}

                tool_tip_content={`Are you, or the collective 
                group your with hungry as a whole?
                We recommend answering what most people want to do.
                Vote with a show of hands if you need too.
                Yes for hungry wins ties.`}

                onclick_1={() => {
                    directAnswer("");
                    nextQuestion("Stay or Leave your current location?")
                }}
                answer_1={"No"}
                onclick_2={() => {
                    directAnswer("hungry");
                    nextQuestion("Stay or Leave your current location?")
                }}
                answer_2={"Yes"}
                backToLastQuestion={backToLastQuestion}
            />
        )
    } else if (currentQuestion === "Stay or Leave your current location?") {
        return (
            <MultiQustion
                question={"Stay or Leave your current location?"}

                tool_tip_content={`To stay would mean limiting you and or your group
                 to your current surroundings. Leaving is represented by 
                 actively needing to travel somewhere.`}

                onclick_1={() => {
                    directAnswer("stay");
                    nextQuestion("HungryFork")
                }}
                answer_1={"Stay"}
                onclick_2={() => {
                    directAnswer("leave");
                    nextQuestion("HungryFork")
                }}
                answer_2={"Leave"}
                backToLastQuestion={backToLastQuestion}
            />
        )
    }
    //if hungry and leave
    else if (currentQuestion === "What kind of dining?" || currentQuestion === "Want to prepare your own food?") {
        return (

            <HungryFlow
                currentQuestion={currentQuestion}
                directAnswer={directAnswer}
                multiSelectAnswers={multiSelectAnswers}
                nextQuestion={nextQuestion}
                endQuiz={endQuiz}
                backToLastQuestion={backToLastQuestion}
            />
        )
    }
    else {
        return (

            <NotHungryFlow
                currentQuestion={currentQuestion}
                directAnswer={directAnswer}
                multiSelectAnswers={multiSelectAnswers}
                nextQuestion={nextQuestion}
                endQuiz={endQuiz}
                backToLastQuestion={backToLastQuestion}
            />
        )
    }
}

export default BaseFlow
