import React, { Component } from 'react'
// import Activities from '../../api/activities';
import { NavLink, Redirect } from 'react-router-dom';
import './EndQuizResult.css';

export class EndQuizResult extends Component {

    _isMounted = false;

    constructor(props) {
        super(props)

        this.state = {
            results: [],
            loading: true,
            need_to_check_prereqs: false,
            prereqs:[]
        }
        this.doneWithPrereqs=this.doneWithPrereqs.bind(this);
    }
        

    async componentDidMount() {
        this._isMounted = true;
        let activities = []
        // if (this.props.location.answerCode) {
        //     activities = await Activities.getQuizResults(this.props.location.answerCode);
        //     let savedAnswerCode = JSON.stringify(this.props.location.answerCode)
        //     localStorage.setItem('savedAnswerCode', savedAnswerCode);
        // } else {
        //     let localstoragejson = localStorage.getItem('savedAnswerCode')
        //     console.log("localstoragejson: ", localstoragejson)
        //     let savedAnswerCode = JSON.parse(localStorage.getItem('savedAnswerCode'));
        //     activities = await Activities.getQuizResults(savedAnswerCode);
        // }

        //This may be this.props.location.activities as an activities list of the the activities that have been confirmed as viable via the prereqs

        if (this.props.location.activities) {
            activities = this.props.location.activities;
            // activities = await Activities.getQuizResults(this.props.location.queryList);
            // let savedQueryList = JSON.stringify(this.props.location.queryList)
            // localStorage.setItem('savedQueryList', savedQueryList);
        } else {
            activities = JSON.parse(localStorage.getItem('activityResults'));
        }

        // let token = sessionStorage.getItem('token');
        // if (token != null) {
        //     Auth.isAuthenticated = true;
        // }



        if (activities) {
            if( this._isMounted === true){
                this.setState({
                    results: activities,
                    loading: false
                })
            }

            ///-----------------------------------------------
            /// I will refactor this into a prereqs page. 
            //Check if activities have prereqs
            // let prereqs_array=[]
            // for (let i=0; i<activities.length;i++){
            //     let prereq=""
            //     let prereq_description=""
            //     if(activities[i].prereq && activities[i].prereq!==''){
            //         prereq=activities[i].prereq
            //     }
            //     if(activities[i].prereq_description && activities[i].prereq_description!==''){
            //         prereq_description=activities[i].prereq_description
            //     }

            //     let prereq_obj={prereq,prereq_description};
            //     if(prereq !=='' ){
            //         prereqs_array.push(prereq_obj)
            //     }
            // }
            // console.log("prereqs_array", prereqs_array)
            // if (prereqs_array.length !== 0){
                
            //     this.setState({
            //         prereqs: prereqs_array,
            //         need_to_check_prereqs:true
            //     })
            // }
            ///------------------------------------------------
            

        } else {
            //there were no activies that fit the answer
            console.log("there were no activies that fit the answer")
            this.setState({ loading: false })
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
      }


    doneWithPrereqs(){
        this.setState({need_to_check_prereqs:false})
    }

    render() {
        const { loading, results } = this.state

        let quizEnded=sessionStorage.getItem("quizEnded");
        if (quizEnded==="False"){
            return (
                <Redirect to={{
                    pathname: '/',
                }}
                />
            )
        }

        // if(this.state.need_to_check_prereqs){
        //     return(
        //         <div>
        //             There are pre reqs!
        //             <ul>{this.state.prereqs.map(prereq=>{
        //                 return <li key={prereq.prereq}>{prereq.prereq}:{prereq.prereq_description}</li>
        //             })}</ul>
        //             <button onClick={this.doneWithPrereqs}>Done with prereqs</button>
        //         </div>
        //     )
        // }
        

        if (!results) {
            return (
                <div>
                    <h1>We will have your results any second now</h1>
                </div>
            )
        } else {
            //console.log("State Results: ", results)
            //console.log("State Results.length: ", results.length)
            return (
                <div className="EndResultsBackgroundDiv">
                    <div className="EndResultsTopBarDiv">

                    </div>
                    <div className="EndResultsContentDiv">
                        {!loading && <h1>These are your results!</h1>}
                        {!loading && <ul>{results.map((activity) => {
                            return (<li key={activity._id}><div>
                                
                                <span><a href={`/quiz/results/${activity._id}`}>{activity.title}</a></span>

                            </div></li>)
                        })}</ul>}
                        {loading && <h1>Loading results...</h1>}
                        {!loading && results.length === 0 && <h1>Sorry, We couldnt find anything for you based on your answers</h1>}

                        <div className="CenterAll">
                            <button className="btn btn-primary">
                                <NavLink className="btn-link" to="/quiz" onClick={()=>{ localStorage.clear(); sessionStorage.setItem("quizEnded", "False") }} >Take The Quiz again!</NavLink>
                            </button>
                        </div>

                      {this.state.prereqs.length !== 0 && <div>
                            <p>Prereqs exist!!</p>
                                    </div> }  

                    </div>

                </div>
            )
        }

    }
}

export default EndQuizResult

