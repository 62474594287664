import React from 'react'
// import { Redirect } from 'react-router-dom';
import MultiQustion from '../../../common/MultiQustion'
import MultiSelectQustion from '../../../common/MultiSelectQuestion'

function NotHungryflow(props) {

    let {multiSelectAnswers, directAnswer, nextQuestion, endQuiz, backToLastQuestion, currentQuestion}=props
    if (currentQuestion === "Want to be inside or outside?") {
        return (

            <MultiQustion
            question={"Want to be inside or outside?"}
            onclick_1={() => { directAnswer("inside"); 
                nextQuestion("How much are you willing to spend?") }}
            answer_1={"Inside"}
            onclick_2={() => { directAnswer("outside"); 
                nextQuestion("How much are you willing to spend?") }}
            answer_2={"Outside"}
            backToLastQuestion={backToLastQuestion}
            />

        )
    } else if (currentQuestion === "How much are you willing to spend?"){

        return(
            <MultiQustion
            question={"How much are you willing to spend per person?"}

            tool_tip_content={`Not all activites cost money,
             but its helpful for us to get a general idea of how much you or your
             group is willing to spend per person on average.`}

            answer_1={"Nothing"}
            onclick_1={() => {
                directAnswer("free");
                nextQuestion("Want to do something that allows for high social verbal interaction?")
            }}

            answer_2={"15 USD or less"}
            onclick_2={() => {
                directAnswer("15dollarsOrLess");
                nextQuestion("Want to do something that allows for high social verbal interaction?")
            }}

            answer_3={"50 USD or less"}
            onclick_3={() => {
                directAnswer("50dollarsOrLess");
                nextQuestion("Want to do something that allows for high social verbal interaction?")
            }}

            answer_4={"50 USD or more"}
            onclick_4={() => {
                directAnswer("50dollarsOrMore");
                nextQuestion("Want to do something that allows for high social verbal interaction?")
            }}

            backToLastQuestion={backToLastQuestion}
        />
        )
       

    }else if (currentQuestion === "Want to do something that allows for high social verbal interaction?") {
        return (

            <MultiQustion
            question={"Want to do something that allows for high social verbal interaction?"}

            tool_tip_content={`Do you or the collective group want to do an activity that allows
            for, or encourges, people to verbally interact with one another?`}

            onclick_1={() => { nextQuestion("Want to do something spiritual?"); 
                directAnswer("") }}
            answer_1={"No"}
            onclick_2={() => { nextQuestion("Want to do something spiritual?"); 
                directAnswer("relational") }}
            answer_2={"Yes"}
            backToLastQuestion={backToLastQuestion}
            />

        )
    } else if (currentQuestion === "Want to do something spiritual?") {
        return (

            <MultiQustion
            question={"Want to do something spiritual?"}

            tool_tip_content={`spiritual meaning an activity that is healthy for your soul`}

            onclick_1={() => { nextQuestion("Want to do something intellectual?"); 
                directAnswer("") }}
            answer_1={"No"}
            onclick_2={() => { nextQuestion("Want to do something intellectual?"); 
                directAnswer("spiritual") }}
            answer_2={"Yes"}
            backToLastQuestion={backToLastQuestion}
            />

        )
    } else if (currentQuestion === "Want to do something intellectual?") {
        return (

            <MultiQustion
            question={"Want to do something intellectual?"}

            tool_tip_content={`Intellectual meaning an activity that may involve mental effort.`}

            onclick_1={() => { nextQuestion("Want to do something physical?"); 
                directAnswer("") }}
            answer_1={"No"}
            onclick_2={() => { nextQuestion("Want to do something physical?"); 
                directAnswer("intellectual") }}
            answer_2={"Yes"}
            backToLastQuestion={backToLastQuestion}
            />

        )
    } else if (currentQuestion === "Want to do something physical?") {
        return (

            <MultiQustion
            question={"Want to do something physical?"}

            tool_tip_content={`physical meaning an activiy that may involve physical movement. 
            You will likely not be sitting in one place`}

            onclick_1={() => { nextQuestion("Want to play some sort of game?"); 
            directAnswer("") }}
            answer_1={"No"}
            onclick_2={() => { nextQuestion("Want to play some sort of game?"); 
            directAnswer("physical") }}
            answer_2={"Yes"}
            backToLastQuestion={backToLastQuestion}
            />

        )
    } else if (currentQuestion === "Want to play some sort of game?") {
        return (

            <MultiQustion
            question={"Want to play some sort of game?"}

            tool_tip_content={`A game typically involves some sort of 
            sport or competition with winners and losers. 
            The Alternative would be an activity with little to no competition `}

            onclick_1={() => { endQuiz("") }}
            answer_1={"No"}
            onclick_2={() => { nextQuestion("What kind of games are you interested in?"); 
            directAnswer("game") }}
            answer_2={"Yes"}
            backToLastQuestion={backToLastQuestion}
            />

        )
    } else if (currentQuestion === "What kind of games are you interested in?") {
        return (

            <MultiSelectQustion
            question={"Want to play some sort of game? Click all that apply"}

            tool_tip_content={`Choose all that apply `}

            select_1={{label:"Talking Game", tag:"talking_game"}}
            select_2={{label:"Paper and Pencil Game", tag:"paperpencil_game"}}
            select_3={{label:"Yard Game", tag:"yard_game"}}
            select_4={{label:"Sports Game", tag:"sports_game"}}
            select_5={{label:"Table Game", tag:"table_game"}}
            select_6={{label:"Card Game", tag:"card_game"}}
            select_7={{label:"Board Game", tag:"board_game"}}
            select_8={{label:"Party Game", tag:"party_game"}}
            select_9={{label:"Video Game", tag:"video_game"}}

            addTagsMethod={multiSelectAnswers}
            after_multi_select={() => { endQuiz("") }}
            backToLastQuestion={backToLastQuestion}
            />

        )
    }
    
    // else {
    //     return (
    //         <Redirect to={{
    //             pathname: '/UnknownPage',
    //         }}
    //         />
    //     )
    // }
    
}

export default NotHungryflow
