import React from 'react';
import { Route , Switch} from "react-router-dom";
import HomePage from './components/home/HomePage';
import AdminPage from './components/admin/AdminPage';
import AdminEditPage from './components/admin/AdminEditPage';
import AdminNewPage from './components/admin/AdminNewPage';
import NotFoundPage from './components/common/NotFoundPage';
import QuizPage from './components/Quiz/QuizPage';
import EndQuizResult from './components/Quiz/EndQuizResult';
import ProtectedRoute from './components/common/ProtectedRoute';
import Activity from './components/Quiz/Activity';
import PreResultsPage from './components/preresults/PreResultsPage';

function App() {
  return (
    <div className="fullpage">
    <div className=" container">
      </div>

      <Switch>
      <Route exact path="/" component={HomePage} />
      <ProtectedRoute exact path="/quiz/" component={QuizPage}/>
      <ProtectedRoute exact path="/quiz/preresults/" component={PreResultsPage}/>
      <ProtectedRoute exact path="/quiz/results" component={EndQuizResult}/>
      <ProtectedRoute exact path="/quiz/results/:id/" component={Activity}/>
      <ProtectedRoute exact path="/decide-admin/" component={AdminPage} />
      <ProtectedRoute path="/decide-admin/list/" component={AdminPage} />
      <ProtectedRoute path="/decide-admin/new/" component={AdminNewPage} />
      <ProtectedRoute path="/decide-admin/edit/:id/" component={AdminEditPage} />
      <Route component={NotFoundPage} />
      </Switch>
      
      
     
    
  </div>
  );
}

export default App;
