import React from 'react'

function MultiQustion(props) {

    const {onclick_1, onclick_2, onclick_3, onclick_4, onclick_5, 
        answer_1, answer_2, answer_3, answer_4, answer_5, question, backToLastQuestion, tool_tip_content} =props

    return (
        <div className="dualityQuestionDiv">

            <h1>{question}</h1>
            {tool_tip_content && <label className="tool tooltiplabel" data-tip={tool_tip_content} >More Context</label>}
            <button className="btn btn-primary" onClick={onclick_1}>{answer_1}</button>
            {answer_2 && <button className="btn btn-primary" onClick={onclick_2}>{answer_2}</button>}
            {answer_3 && <button className="btn btn-primary" onClick={onclick_3}>{answer_3}</button>}
            {answer_4 && <button className="btn btn-primary" onClick={onclick_4}>{answer_4}</button>}
            {answer_5 && <button className="btn btn-primary" onClick={onclick_5}>{answer_5}</button>}

            <button className="btn btn-secondary goBackBtn" onClick={backToLastQuestion}>Go Back</button>
        </div>
    )
}

export default MultiQustion
