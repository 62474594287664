/* eslint-disable import/no-named-as-default */
const axios = require('axios');
const getAxiosConfig = require('./getAxiosConfig');

//remember these must be exact for powershell script!!!!!
const baseUrl="https://fgr-decide.herokuapp.com/";//prod
//const baseUrl="http://localhost:5000/";//local dev

class Tags{

    static getAllTags(){
        return new Promise(async (resolve, reject)=>{
            try{
                let axiosConfig = getAxiosConfig()

                let tags = await axios.get(baseUrl + 'tags/all', axiosConfig);
                if (tags==null) resolve(null) ;
                console.log("tags data",tags.data.tags);
                resolve(tags.data.tags) ;
            }catch(e){
                reject(e)
            }
        })
    }


}



export default Tags;
