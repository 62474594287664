import React from 'react';
import MultiQustion from '../../../common/MultiQustion'

function HungryFlow(props) {

    let {endQuiz, backToLastQuestion, currentQuestion }=props
    //if hungry and leave
    if (currentQuestion === "What kind of dining?") {
        return (

            <MultiQustion
            question={"What kind of dining?"}

            tool_tip_content={`Resturants are places where you sit
             down somewhere for extended period of time.
             Fast causal is where you may order at the counter and 
             wait for a short period of time. A fast food 
             place would mostly be represented by places that
             would have drive throughs at their location. `}

            onclick_1={() => {endQuiz("resturants") }}
            answer_1={"resturants"}
            onclick_2={() => {endQuiz("fast_casual") }}
            answer_2={"fast casual"}
            onclick_3={() => {endQuiz("fast_food") }}
            answer_3={"fast food"}
            backToLastQuestion={backToLastQuestion}
            />

        )
       //if hungry and stay
    } else if (currentQuestion === "Want to prepare your own food?") {
        return (

            <MultiQustion
            question={"Want to prepare your own food?"}

            tool_tip_content={`Are you and or your collective group,
            willing to prepare your own food.
            This would assume you are in some 
            environment that would allow for some food prep.`}

            onclick_1={() => {endQuiz("takeOut") }}
            answer_1={"No"}
            onclick_2={() => {endQuiz("foodPrep") }}
            answer_2={"Yes"}
            backToLastQuestion={backToLastQuestion}
            />

        )
    }
    
}

export default HungryFlow
