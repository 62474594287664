import React from 'react'
import { NavLink } from "react-router-dom";
import './NotFoundPage.css'

function NotFoundPage() {
    return (
        <div className="NotFoundDiv" >

          <div className="NotFoundPageContentDiv">
          <h1>Uh OH...</h1>
          <h4>404 Error - We couldnt find the page you were trying to reach.</h4>
          <button className="btn btn-primary">
          <NavLink
                className="btn-link"
                exact
                to="/"
              >
                Go Home
              </NavLink>
          </button>
            
          </div>
            
        </div>
    )
}

export default NotFoundPage