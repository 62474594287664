import React, { Component } from 'react'
import { NavLink } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Auth from "../../auth/auth";
import './HomePage.css';

//https://www.youtube.com/watch?v=ahSz_bmePNk this will help for temparity showing invalid input.

export class HomePage extends Component {
    constructor(props) {
        super(props)

        this.state = {
            loading: false,
            password: "",
            error: "",
        }

        this.login = this.login.bind(this);
        this.onChange = this.onChange.bind(this);
        this.logout = this.logout.bind(this);
        this.onKeyPress = this.onKeyPress.bind(this);
    }

    componentWillMount() {
        let token = sessionStorage.getItem('token');
        if (token != null) {
            Auth.isAuthenticated = true;
        }
    }


    async login() {

        try {
            this.setState({
                loading: true
            });

            let res = await Auth.login(this.state.password);
            console.log("res:", res);

            this.setState({
                loading: false,
                // authenticated:Auth.isAuthenticated
            });
        } catch (e) {
            console.log(e);
            this.setState({
                loading: false,
            });
            if (typeof (e.Error) == "string") {

                toast.error(`Error: ${e.Error}`);
            } else {
                toast.error(`Error: ${JSON.stringify(e)}`);
            }

        }

    }

    logout() {
        Auth.logout();
        //force update with similated state update
        this.forceUpdate();
    }

    onChange(e) {
        this.setState({
            password: e.target.value
        })
    }

    onKeyPress(e) {
        if (e.key === 'Enter' && this.state.password) {
            document.getElementById("AddBtn").click();
        } else if (e.key === 'Enter') {
            toast.error(`Error: Please enter the password`);
        }
    }

    render() {

        let { password, loading } = this.state

        return (


            <div className="homePageBackgroundDiv">
                <div className="HomePageContentDiv">
                    <h1 className="title">Decide</h1>

                    <h3 className="welcomeMessage" >Trying to Decide what to do??</h3>
                    <p>Answer a few questions and we will help you brainstorm a solution!</p>
                    {loading && <h2>Loading...</h2>}

                    {Auth.isAuthenticated ?
                        <div className="takequizBtnDiv">
                            <NavLink
                                id="takeQuiz"
                                className="btn btn-primary"
                                to="quiz/"
                                onClick={()=>{ localStorage.clear(); sessionStorage.setItem("quizEnded", "False") }}
                            >Take The Quiz!</NavLink>
                        </div>
                        :
                        <div className="passwordDiv" >
                            <label>Password</label>
                            <div className="inputDiv">
                                <input className={(password ? " password" : "")} onKeyPress={this.onKeyPress} onChange={this.onChange} value={password} type="password" />
                                {password && <button id="AddBtn" onClick={this.login}>login</button>}
                            </div>
                            <p>Decide is in development, <a href="mailto:frankgriviera@outlook.com">Contact Author</a> for temp password.</p>
                        </div>
                    }
                    <ToastContainer />
                </div>

            </div>
        )
    }
}

export default HomePage

