import React, { Component } from 'react';
//import { Redirect } from 'react-router-dom';
//import HungryFlow from './Flows/HungryFlow/HungryFlow'
//import NotHungryFlow from './Flows/NotHungryFlow/NotHungryFlow';
import './QuizStyles.css';
//import MultiQustion from '../common/MultiQustion';
import BaseFlow from './Flows/BaseFlow';


export class QuizPage extends Component {
    constructor(props) {
        super(props)

        this.state = {
            answer: "1",
            quizEnded: false,
            queryList: [],
            currentQuestion: "How many people?"
        }
        this.onInputChange = this.onInputChange.bind(this);
        this.selectQuestion = this.selectQuestion.bind(this);
        this.backToLastQuestion = this.backToLastQuestion.bind(this);
        this.directAnswer = this.directAnswer.bind(this);
        this.multiSelectAnswers = this.multiSelectAnswers.bind(this);
        this.endQuiz = this.endQuiz.bind(this);
        this.nextQuestion = this.nextQuestion.bind(this);
    }

    async componentDidMount() {

        //Bring in Current Question on page refresh
        let localstorage_currentQuestion = localStorage.getItem('currentQuestion')
        if (localstorage_currentQuestion != null) {
            let currentQuestion = localStorage.getItem('currentQuestion');
            //console.log("Question in local storage: ", currentQuestion)
            this.setState({
                currentQuestion: currentQuestion
            })
        }

        //Bring in Saved queryList on page refresh
        let localstoragejson = localStorage.getItem('savedQueryList')
        if (localstoragejson != null) {
            let savedQueryList = JSON.parse(localStorage.getItem('savedQueryList'));
            this.setState({
                queryList: savedQueryList
            })
        }


    }

    onInputChange(e) {
        if (e.target.name === 'answer')
            this.setState({
                answer: e.target.value
            });
    }

    backToLastQuestion() {

        let newQueryList = this.state.queryList;
        // console.log("select: this.state.answer: ", this.state.answer)
        newQueryList.pop();

        updatelocalStorage(newQueryList);

        this.setState({
            queryList: newQueryList
        })

        let questionHistory = JSON.parse(localStorage.getItem('questionHistory'));

        let lastquestion = questionHistory.slice(-1)[0];
        //console.log("lastquestion", lastquestion)
        questionHistory.pop();
        localStorage.setItem('questionHistory', JSON.stringify(questionHistory));

        if (lastquestion === "How many people?") {
            this.setState({ answer: "1" })
        }

        localStorage.setItem('currentQuestion', lastquestion);
        this.setState({
            currentQuestion: lastquestion
        })
    }

    nextQuestion(nextTitle) {

        //store previous question in question array
        if (this.state.currentQuestion === "How many people?") {
            //console.log("Last Question in local storage: ", "How many people?")
            let questionHistory = ["How many people?"];

            localStorage.setItem('questionHistory', JSON.stringify(questionHistory));
        } else {
            let lastquestion = localStorage.getItem('currentQuestion');
            let questionHistory = JSON.parse(localStorage.getItem('questionHistory'));
            questionHistory.push(lastquestion)
            //console.log("NewQuestionHistory ", questionHistory)
            localStorage.setItem('questionHistory', JSON.stringify(questionHistory));
        }

        //console.log("nextTitle", nextTitle)

        //--------------Forks---------------------

        if (nextTitle === "HungryFork") {
            if (this.state.queryList.includes('hungry')) {
                if (this.state.queryList.includes('leave')) {
                    nextTitle = "What kind of dining?"
                } else if (this.state.queryList.includes('stay')) {
                    nextTitle = "Want to prepare your own food?"
                }

            } else {
                nextTitle = "Want to be inside or outside?"
            }
        }

        //-----------------------------------

        //This was only necessary if I had this question be a select.
        // if (nextTitle === "How Much Time Do you have?") {
        //     this.setState({ answer: "30minutesOrLess" })
        // }

        localStorage.setItem('currentQuestion', nextTitle);

        //console.log("nextTitle", nextTitle)
        this.setState({
            currentQuestion: nextTitle
        })
    }

    //to answer for select and dropdown questions
    selectQuestion() {

        let newQueryList = this.state.queryList;
        // console.log("select: this.state.answer: ", this.state.answer)
        newQueryList.push(this.state.answer);

        updatelocalStorage(newQueryList);

        this.setState({
            queryList: newQueryList
        })

    }

    // to answer muti question and quality questions with only one answer
    directAnswer(answer) {

        let newQueryList = this.state.queryList;
        // console.log("this.state.queryList : ", this.state.queryList)
        // console.log("direct: answer: ", answer)
        newQueryList.push(answer);

        updatelocalStorage(newQueryList);

        this.setState({
            queryList: newQueryList
        })
    }

    // to add answers from multi selects
    multiSelectAnswers(answerArray) {

        let newQueryList = this.state.queryList;
        // console.log("this.state.queryList : ", this.state.queryList)
        // console.log("direct: answer: ", answer)
        for(let i=0;i<answerArray.length;i++){
            newQueryList.push(answerArray[i]);
        }


        updatelocalStorage(newQueryList);

        this.setState({
            queryList: newQueryList
        })
    }

    endQuiz(answer) {
        //After async operation of getting results, then display in EndQuizResults

        sessionStorage.setItem("quizEnded", "True")

        if (answer === "") {
            this.setState({
                quizEnded: true
            })
        } else {
            let newQueryList = this.state.queryList;
            //console.log("End quiz: answer: ", answer)
            newQueryList.push(answer);

            updatelocalStorage(newQueryList);

            //console.log("End quiz QueryList: ", newQueryList)
            this.setState({
                queryList: newQueryList,
                quizEnded: true
            })
        }
    }

    //Base render
    render() {
        return (
            <BaseFlow
                {...this.state}
                onInputChange={this.onInputChange}
                selectQuestion={this.selectQuestion}
                backToLastQuestion={this.backToLastQuestion}
                directAnswer={this.directAnswer}
                multiSelectAnswers={this.multiSelectAnswers}
                endQuiz={this.endQuiz}
                nextQuestion={this.nextQuestion}
            />
        )
    }
};

function updatelocalStorage(queryList) {

    let savedQueryList = JSON.stringify(queryList);
    localStorage.setItem('savedQueryList', savedQueryList);
};

export default QuizPage;
