import React, { Component } from 'react'
import "./PreResultsMultiSelect.css"

export class PreResultsMultiSelect extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            activities_to_add:[]     
        }

        this.toggleActivity=this.toggleActivity.bind(this);
    }

    //When This component loads, get this.props.activities_with_prereqs which has all the activities to check
    //and ultimately send back the confirmed activiy items in "PreReqConfirmationArray(this.state.activities_to_add);"
    // My Plan is this, I will add a on component start up method that will create the prereq list without dubplicates from the activities_with_prereqs list
    // Then when the compoennt is rendered it will have only the unique prereqs.
    // When the user clicks "done" a new function I create will fire, it will take that list and for each prereq that is checked
    //it will look through the original this.props.activities_with_prereqs and if an activity prereq title matches a title clicked then
    // add that original activity to the new activity list and when thats done finish it.
    // addtionally I need to update the Verbish in this component. If I do I toggle, it should be for a unique prereq and no longer an exact activity.

    toggleActivity(Activity){
        //Add if its not already in array, remove if it already is.
        let newActivityList = this.state.activities_to_add
        if(newActivityList.includes(Activity)){
            //remove Activity since already in 
            var index = newActivityList.indexOf(Activity);
            newActivityList.splice(index, 1);
        }else{
            //add Activity since already in 
            newActivityList.push(Activity);
        }
        

        this.setState({
            activities_to_add: newActivityList
        })
    }
    
    render() {

        const { question, activities_with_prereqs, tool_tip_content, PreReqConfirmationArray, after_multi_select} =this.props

            const {activities_to_add} = this.state
        return (
            <div className="preResultMultiSelectDiv">

                <h1>{question}</h1>
                {tool_tip_content && <label className="tool tooltiplabel" data-tip={tool_tip_content} >More Context</label>}

                <div className='preResultsSelectButtons'>
                    {activities_with_prereqs.map(activity =>{
                    return <button key={activity._id} className={activities_to_add.includes(activity) ? "selected-multiselect preResultsMultiSelect" : "unselected-multiselect preResultsMultiSelect"} onClick={()=>{this.toggleActivity(activity)}}>{activity.prereq}</button>
                    })}
                </div>

                <button className="btn btn-primary" onClick={() => {
                        //Add a new function in this component that will call these bottem two methods in addtion to more logic
                        PreReqConfirmationArray(this.state.activities_to_add);
                        after_multi_select();
                    }}>NEXT</button>
            </div>
        )
    }
}

export default PreResultsMultiSelect





