import React, { Component } from 'react'
import Activities from '../../api/activities';
import Tags from '../../api/tags';
import { NavLink, Redirect } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import validateTags from '../../validation/validateTags';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './AdminActivityDetail.css'

export class AdminEditPage extends Component {
    constructor(props) {
        super(props)

        this.state = {
            title: "",
            description: "",
            instructions: "",
            selected_tag: "",
            prereq:"",
            prereq_description:"",
            relational: false,
            spiritual: false,
            intellectual: false,
            physical: false,
            allTimes: false,
            people_all: false,
            people_moreThenOne: false,
            addNewSucess: false,
            tags: [],
            allTags: [],
            original_tags:[],
            tagsForRestore:[],
            activity: {
                title: "", description: "",
                instructions: "", tags: [],
                prereq:"", prereq_description:""
            },
            deleted: false,
            error: ""

        }

        this.onInputChange = this.onInputChange.bind(this);
        this.editActivity = this.editActivity.bind(this);
        this.addTag = this.addTag.bind(this);
        this.removeTag = this.removeTag.bind(this);
        this.deleteActivity = this.deleteActivity.bind(this);
        this.restoreTags=this.restoreTags.bind(this);
    }

    //LifeCycle method
    async componentWillMount() {
        try {
            let activityToEdit = await Activities.getActivityById(this.props.match.params.id);
            let allTags = await Tags.getAllTags();
            let og_array = activityToEdit.tags.slice()
            console.log("activityToEdit.tags: ", activityToEdit.tags)

            let prereq = activityToEdit.prereq ? activityToEdit.prereq : "";
            let prereq_description = activityToEdit.prereq_description ? activityToEdit.prereq_description : ""

            this.setState({
                tags: activityToEdit.tags,
                title: activityToEdit.title,
                description: activityToEdit.description,
                instructions: activityToEdit.instructions,
                prereq:prereq,
                prereq_description:prereq_description,
                allTags: allTags,
                original_tags:og_array
            })
        } catch (e) {
            console.log("Error: ", e)
        }
    }

    onInputChange(e) {
        if (e.target.name === 'title')
            this.setState({
                title: e.target.value
            });
        if (e.target.name === 'description')
            this.setState({
                description: e.target.value
            });
        if (e.target.name === 'instructions')
            this.setState({
                instructions: e.target.value
            });
        if (e.target.name === 'prereq')
            this.setState({
                prereq: e.target.value
            });
        if (e.target.name === 'prereq_description')
            this.setState({
                prereq_description: e.target.value
            });
        if (e.target.name === 'select_tag')
            this.setState({
                selected_tag: e.target.value
            });
    }

    addTag(event) {
        event.preventDefault()
        let newTagList = this.state.tags

        if (this.state.selected_tag && !newTagList.includes(this.state.selected_tag)) {
            newTagList.push(this.state.selected_tag)
        }
        this.setState({
            tags: newTagList
        });
    }

    restoreTags(){
        let newTagList = this.state.tags
        let tagsForRestore = this.state.tagsForRestore
        for(let i=0;i<tagsForRestore.length;i++){
            console.log("tagsForRestore[i]", tagsForRestore[i])
            newTagList.push(tagsForRestore[i]);
        }

        this.setState({
            tagsForRestore:[],
            tags: newTagList
        });

    }

    removeTag(e) {
        var newTags = this.state.tags

        var index = newTags.indexOf(e.target.value);
        
        //add to tags to restore if its an original tag
        let restorableTags = this.state.tagsForRestore;
        if(this.state.original_tags.includes(newTags[index])){
            restorableTags.push(newTags[index]);
        }
        
        if (index > -1) {
            newTags.splice(index, 1);
        }
        this.setState({
            tagsForRestore:restorableTags,
            tags: newTags

        })
    }

    async editActivity(event) {
        event.preventDefault()

        let editedTags = validateTags(this.state.tags);

        if (editedTags.isValid) {
            //Add tags based on inputs
            let editedActivity = {
                title: this.state.title,
                description: this.state.description,
                instructions: this.state.instructions,
                prereq: this.state.prereq,
                prereq_description: this.state.prereq_description,
                tags: this.state.tags
            }
            this.setState({
                activity: editedActivity,
                error: ""
            });
            //Call Api POST
            try {
                let response = await Activities.editActivity(this.props.match.params.id, editedActivity)
                if (response.status === 200) {
                    console.log("Activity succesfully edited")
                    toast.success(`Success: Activity succesfully edited`);
                } else {
                    console.log("Not sure activity was added")
                }
            } catch (e) {
                console.log(e)
                console.log(e.response.data)
                this.setState({
                    error: e.response.data
                })
                toast.error(`Error: ${e.response.data}`);
            }
        } else {
            this.setState({
                error: editedTags.errors
            })
            toast.error(`Error: ${editedTags.errors}`);
        }



    }

    deleteActivity() {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='custom-ui'>
                        <h1>Are you sure?</h1>
                        <p>You want to delete this file?</p>
                        <button onClick={onClose}>No</button>
                        <button
                            onClick={async () => {
                                try {
                                    let response = await Activities.deleteActivityById(this.props.match.params.id)
                                    if (response.status === 200) {
                                        console.log("Activity succesfully deleted")
                                        toast.info("Activity succesfully deleted");
                                    } else {
                                        console.log("Not sure activity was deleted")
                                        toast.info("Not sure activity was deleted");
                                    }
                                } catch (e) {
                                    console.log(e)
                                }

                                this.setState({
                                    deleted: true
                                })

                                onClose();



                            }}
                        >
                            Yes, Delete it!
                  </button>
                    </div>
                );
            }
        });



    }




    render() {
        if (this.state.deleted) {
            return (
                <Redirect to={{
                    pathname: '/decide-admin/list'
                }}
                />
            )
        } else {

            let {error, description, title, allTags, 
                original_tags, tags, instructions, prereq, 
                prereq_description, selected_tag} = this.state
            return (
                <div className="AdminEditPage">
                    <h1>AdminEditPage</h1>

                    <NavLink to="/decide-admin">Go to admin Home</NavLink>

                    <form  >
                        {error && <p>Error:{error}</p>}
                        {/* <label htmlFor="title">Title</label>
                        <input onChange={this.onInputChange} name="title" value={this.state.title} />
                        <label htmlFor="description">description</label>
                        <textarea onChange={this.onInputChange} name="description" value={this.state.description} />
                        <label htmlFor="instructions">instructions</label>
                        <textarea onChange={this.onInputChange} name="instructions" value={this.state.instructions} />
                        <select name="select_tag" onChange={this.onInputChange} value={this.state.selected_tag}>
                            <option value="">None</option>
                            {this.state.allTags.map(tag => {
                                return <option key={tag} value={tag}>{tag}</option>
                            })}
                        </select>
                        <button onClick={this.addTag}>Add Tag</button> */}
                        <div className="titleDiv">
                            <label htmlFor="title">Title</label>
                            <input onChange={this.onInputChange} name="title" value={title} />
                        </div>
                        <div className="descriptionDiv">
                            <label htmlFor="description">description</label>
                            <textarea onChange={this.onInputChange} name="description" value={description} />
                        </div>
                        <div className="intructionsDiv">
                            <label htmlFor="instructions">instructions</label>
                            <textarea onChange={this.onInputChange} name="instructions" value={instructions} />
                        </div>
                        <div className="prereqDiv">
                            <label htmlFor="prereq">prereq</label>
                            <textarea onChange={this.onInputChange} name="prereq" value={prereq} />
                        </div>
                        <div className="prereq_descriptionDiv">
                            <label htmlFor="prereq_description">prereq_description</label>
                            <textarea onChange={this.onInputChange} name="prereq_description" value={prereq_description} />
                        </div>
                        <div className="tagSelectDiv">
                            <select name="select_tag" onChange={this.onInputChange} value={selected_tag}>
                                <option value="">None</option>
                                {allTags.map(tag => {
                                    return <option key={tag} value={tag}>{tag}</option>
                                })}

                            </select>
                            <button onClick={this.addTag}>Add Tag</button>

                           {(this.state.tagsForRestore.length > 0) && <button type="button" onClick={this.restoreTags} >Restore Og Tags</button>} 
                        </div>


                        

                        <div className="tagsToAddList">
                            <h5>Edited tags to be added</h5>
                            <ul>{tags.map(tag => { return <li key={tag}  >
                                <button className={ original_tags.includes(tag) ? "original_tag" :" "} onClick={this.removeTag} value={tag}>{tag}</button>
                                </li> })}
                            </ul>
                        </div>
                        <NavLink to="/decide-admin">Go to admin Home</NavLink>
                        <button className="btn btn-primary" type="button"  onClick={this.editActivity}>Finish Edit</button>

                        <button className="btn btn-danger" type="button"  onClick={this.deleteActivity}>Delete Activity</button>
                    
                    </form>

                    <ToastContainer />
                </div>
            )
        }


    }
}

export default AdminEditPage
