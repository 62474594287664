import React, { Component } from 'react'
import "./MultiSelectQuestion.css"

export class MultiSelectQuestion extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            tags_to_add:[]     
        }

        this.toggletag=this.toggletag.bind(this);
    }

    toggletag(tag){
        //Add if its not already in array, remove if it already is.
        let newTagsList = this.state.tags_to_add
        if(newTagsList.includes(tag)){
            //remove tag since already in 
            var index = newTagsList.indexOf(tag);
            newTagsList.splice(index, 1);
        }else{
            //add tag since already in 
            newTagsList.push(tag);
        }
        

        this.setState({
            tags_to_add: newTagsList
        })
    }
    
    render() {

        const {select_1, select_2, select_3, select_4, select_5, select_6, select_7, select_8, select_9,
             question, backToLastQuestion, tool_tip_content, addTagsMethod, after_multi_select} =this.props

            const {tags_to_add} = this.state
        return (
            <div className="multiSelectQuestionDiv">

                <h1>{question}</h1>
                {tool_tip_content && <label className="tool tooltiplabel" data-tip={tool_tip_content} >More Context</label>}
                {/* <button className="btn btn-primary" onClick={toggletag}>{select_1.label}</button> */}
                {/* <button className="btn btn-primary" onClick={toggletag}>{select_2.label}</button> */}

                <div className='multiSelectButtons'>
                    <button className={tags_to_add.includes(select_1.tag) ? "selected-multiselect multiSelectButton" : "unselected-multiselect multiSelectButton"} onClick={()=>{this.toggletag(select_1.tag)}}>{select_1.label}</button>
                    <button className={tags_to_add.includes(select_2.tag) ? "selected-multiselect multiSelectButton" : "unselected-multiselect multiSelectButton"} onClick={()=>{this.toggletag(select_2.tag)}}>{select_2.label}</button>
                    { select_4 && <button className={tags_to_add.includes(select_4.tag) ? "selected-multiselect multiSelectButton" : "unselected-multiselect multiSelectButton"} onClick={()=>{this.toggletag(select_4.tag)}}>{select_4.label}</button>} 
                    { select_3 && <button className={tags_to_add.includes(select_3.tag) ? "selected-multiselect multiSelectButton" : "unselected-multiselect multiSelectButton"} onClick={()=>{this.toggletag(select_3.tag)}}>{select_3.label}</button>} 
                    { select_5 && <button className={tags_to_add.includes(select_5.tag) ? "selected-multiselect multiSelectButton" : "unselected-multiselect multiSelectButton"} onClick={()=>{this.toggletag(select_5.tag)}}>{select_5.label}</button>} 
                    { select_6 && <button className={tags_to_add.includes(select_6.tag) ? "selected-multiselect multiSelectButton" : "unselected-multiselect multiSelectButton"} onClick={()=>{this.toggletag(select_6.tag)}}>{select_6.label}</button>} 
                    { select_7 && <button className={tags_to_add.includes(select_7.tag) ? "selected-multiselect multiSelectButton" : "unselected-multiselect multiSelectButton"} onClick={()=>{this.toggletag(select_7.tag)}}>{select_7.label}</button>} 
                    { select_8 && <button className={tags_to_add.includes(select_8.tag) ? "selected-multiselect multiSelectButton" : "unselected-multiselect multiSelectButton"} onClick={()=>{this.toggletag(select_8.tag)}}>{select_8.label}</button>} 
                    { select_9 && <button className={tags_to_add.includes(select_9.tag) ? "selected-multiselect multiSelectButton" : "unselected-multiselect multiSelectButton"} onClick={()=>{this.toggletag(select_9.tag)}}>{select_9.label}</button>}
                </div>
                 



                <button className="btn btn-primary" onClick={() => {
                        addTagsMethod(this.state.tags_to_add);
                        after_multi_select();
                    }}>NEXT</button>

                <button className="btn btn-secondary goBackBtn" onClick={backToLastQuestion}>Go Back</button>
            </div>
        )
    }
}

export default MultiSelectQuestion




// function MultiSelectQustion(props) {

//     const {onclick_1, onclick_2, onclick_3, onclick_4, onclick_5, 
//         answer_1, answer_2, answer_3, answer_4, answer_5, question, backToLastQuestion, tool_tip_content} =props

//     return (
//         <div className="dualityQuestionDiv">

//             <h1>{question}</h1>
//             {tool_tip_content && <label className="tool tooltiplabel" data-tip={tool_tip_content} >More Context</label>}
//             <button className="btn btn-primary" onClick={onclick_1}>{answer_1}</button>
//             {answer_2 && <button className="btn btn-primary" onClick={onclick_2}>{answer_2}</button>}
//             {answer_3 && <button className="btn btn-primary" onClick={onclick_3}>{answer_3}</button>}
//             {answer_4 && <button className="btn btn-primary" onClick={onclick_4}>{answer_4}</button>}
//             {answer_5 && <button className="btn btn-primary" onClick={onclick_5}>{answer_5}</button>}

//             <button className="btn btn-secondary goBackBtn" onClick={backToLastQuestion}>Go Back</button>
//         </div>
//     )
// }

// export default MultiSelectQustion
