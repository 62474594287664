import React, { Component } from 'react'
import Activities from '../../api/activities';
import { Redirect } from 'react-router-dom';
import PreResultsMultiSelect from './PreResultsMultiSelect';

export class PreResultsPage extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
            need_to_check_prereqs: true,
            queryList:[],
            activities:[],
            loading:true,
            prereq_activity_array:[]
        }

        this.PreReqConfirmationArray=this.PreReqConfirmationArray.bind(this);
        this.doneWithPreReqs=this.doneWithPreReqs.bind(this);
    }


    async componentDidMount() {
        this._isMounted = true;
        let activities = []


        ///
        //TODO this is where I should call the preview endpoint on the backend to just return prereq actovities based on querylist.
        ///

        if (this.props.location.queryList) {
            activities = await Activities.getQuizResults(this.props.location.queryList);
        } else {
            let localstoragejson = localStorage.getItem('savedQueryList')
            console.log("localstoragejson: ", localstoragejson)
            let savedQueryList = JSON.parse(localStorage.getItem('savedQueryList'));
            activities = await Activities.getQuizResults(savedQueryList);
        }

        //At this point I would only have the activities with prereqs with only minimal info on each.
        //I would still cehck to see if _isMounted is true and then setstate for locading and actovities
        // but I dont need to check if actiovities have prereqs cuz that would be taken care of by the backend endpoint.

        if (activities) {
            if( this._isMounted === true){
                this.setState({
                    activities: activities,
                    loading: false
                })
            }


            //Check if activities have prereqs
            let prereqs_array=[]
            let newActivitiesList = activities.slice(0); //This creates a new refference in memory for array.
            for (let i=0; i<activities.length;i++){

                // let prereq=""
                // let prereq_description=""
                if(activities[i].prereq && activities[i].prereq!==''){
                    // prereq=activities[i].prereq

                    //Check here if Any existing prereqs have same name and dont add them if they do (MAYBE got to conrim other logic first)

                    //If prereq_description is null set it to empty string.
                    if(!activities[i].prereq_description){
                        activities[i].prereq_description =""
                    }

                    prereqs_array.push(activities[i])

                    //remove prereq from new activities list
                    var index = newActivitiesList.indexOf(activities[i]);
                    newActivitiesList.splice(index, 1);
                    
                }
                // if(activities[i].prereq_description && activities[i].prereq_description!==''){
                //     prereq_description=activities[i].prereq_description
                // }

                // let prereq_obj={prereq,prereq_description, id: activities[i]._id};
                // if(prereq !=='' ){
                //     prereqs_array.push(prereq_obj)
                // }

                
 
            }


            console.log("prereqs_array", prereqs_array)
            if (prereqs_array.length !== 0){
                
                this.setState({
                    prereq_activity_array: prereqs_array,
                    need_to_check_prereqs:true,
                    activities:newActivitiesList
                })
            }else{
                this.setState({need_to_check_prereqs:false})
            }
            ///------------------------------------------------
            

        } else {
            //there were no activies that fit the answer
            console.log("there were no activies that fit the answer")
            this.setState({ loading: false })
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }


    // to add answers from multi selects
    PreReqConfirmationArray(activitiesArray) {

        let newActivityList = this.state.activities;

        for(let i=0;i<activitiesArray.length;i++){
            newActivityList.push(activitiesArray[i]);
        }

        updatelocalStorageActivityResults(newActivityList);

        this.setState({
            activities: newActivityList
        })
    }

    doneWithPreReqs(){
        this.setState({need_to_check_prereqs:false})
    }
    
    render() {
        let {activities, need_to_check_prereqs} = this.state

        if (!need_to_check_prereqs){
            return (
                <Redirect to={{
                    pathname: '/quiz/results',
    
                    activities: activities
                }}
                />
            )
        }else{
            return(
                <PreResultsMultiSelect
                question={"Do you have the access to the following? Click all that apply"}
    
                tool_tip_content={`Some things are required for certain activities. 
                we want to see if you have what you need before we suggest something you are unable to do.`}
                
                activities_with_prereqs={this.state.prereq_activity_array}
    
                PreReqConfirmationArray={this.PreReqConfirmationArray}
                after_multi_select={this.doneWithPreReqs}

                />
            )
        }

    }
}



function updatelocalStorageActivityResults(activities) {

    let activityResults = JSON.stringify(activities);
    localStorage.setItem('activityResults', activityResults);
};

export default PreResultsPage