/* eslint-disable import/no-named-as-default */
const axios = require('axios');
const getAxiosConfig = require('./getAxiosConfig');

//remember these must be exact for powershell script!!!!!
const baseUrl="https://fgr-decide.herokuapp.com/";//prod
//const baseUrl="http://localhost:5000/";//local dev

class Activities {

    static getAllActivities() {
        return new Promise(async (resolve, reject) => {
            try {
                let axiosConfig = getAxiosConfig()

                let activities = await axios.get(baseUrl + 'activities', axiosConfig);
                if (activities == null) resolve(null);
                //console.log("activities data",activities.data);
                resolve(activities.data);
            } catch (e) {
                reject(e)
            }

        })

    }

    static getQuizResults(queryList) {

        if (queryList) {
            //remove falsy strings in array with filter. 
            queryList = queryList.filter(Boolean);
        }


        return new Promise(async (resolve, reject) => {
            try {
                let axiosConfig = getAxiosConfig()

                let activities = await axios.post(baseUrl + 'solution/v2/', { queryList }, axiosConfig);
                if (activities == null) resolve(null);
                //console.log("activities data",activities.data);
                resolve(activities.data);
            } catch (e) {
                reject(e)
            }
        })
    }

    static createNewActivity(newActivity) {
        return new Promise(async (resolve, reject) => {
            try {
                let axiosConfig = getAxiosConfig()

                let response = await axios.post(baseUrl + 'activities', {
                    tags: newActivity.tags,
                    title: newActivity.title,
                    description: newActivity.description,
                    instructions: newActivity.instructions,
                    prereq: newActivity.prereq,
                    prereq_description: newActivity.prereq_description,
                }, axiosConfig);
                //if (response==null) resolve(null) ;
                console.log(" create new activity response: ", response);
                resolve(response);
            } catch (e) {
                reject(e)
            }
        })
    }

    static editActivity(id, editedActivity) {

        console.log("editedActivity: ", editedActivity)

        return new Promise(async (resolve, reject) => {
            try {
                let axiosConfig = getAxiosConfig()

                let response = await axios.put(baseUrl + 'activities/' + id, {
                    tags: editedActivity.tags,
                    title: editedActivity.title,
                    description: editedActivity.description,
                    instructions: editedActivity.instructions,
                    prereq: editedActivity.prereq,
                    prereq_description: editedActivity.prereq_description,
                }, axiosConfig);
                //if (response==null) resolve(null) ;
                console.log(" create new activity response: ", response);
                resolve(response);
            } catch (e) {
                reject(e)
            }
        })
    }

    static getActivityById(id) {
        return new Promise(async (resolve, reject) => {
            try {
                let axiosConfig = getAxiosConfig()

                let activity = await axios.get(baseUrl + 'activities/' + id, axiosConfig);
                if (activity == null) resolve(null);
                //console.log("activities data",activity.data);
                resolve(activity.data);
            } catch (e) {
                reject(e)
            }

        })
    }

    static deleteActivityById(id) {
        return new Promise(async (resolve, reject) => {
            try {
                let axiosConfig = getAxiosConfig()

                let response = await axios.delete(baseUrl + 'activities/' + id, axiosConfig);
                if (response.data == null) resolve(null);
                console.log("delete activity response", response);
                resolve(response);
            } catch (e) {
                reject(e)
            }

        })
    }

}


export default Activities;
