import React, { Component } from 'react'
import Activities from '../../api/activities';
import Tags from '../../api/tags';
import { NavLink } from 'react-router-dom';
import validateTags from '../../validation/validateTags';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './AdminActivityDetail.css'

export class AdminNewPage extends Component {
    constructor(props) {
        super(props)

        this.state = {
            title: "",
            description: "",
            instructions: "",
            selected_tag: "",
            prereq:"",
            prereq_description:"",
            relational: false,
            spiritual: false,
            intellectual: false,
            physical: false,
            allTimes: false,
            people_all: false,
            people_moreThenOne: false,
            addNewSucess: false,
            tags: [],
            allTags: [],
            newActivity: {
                title: "", description: "",
                instructions: "", tags: [],
                prereq:"", prereq_description:""
            },
            error: ""
        }

        this.onInputChange = this.onInputChange.bind(this);
        this.addActivity = this.addActivity.bind(this);
        this.addTag = this.addTag.bind(this);
        this.removeTag = this.removeTag.bind(this);

    }

    async componentDidMount() {

        try {
            let allTheTags = await Tags.getAllTags();
            let startingTags = allTheTags.slice(0);

            this.setState({ tags: startingTags, allTags: allTheTags })
        } catch (e) {
            console.log("Error: ", e)
        }
    }

    onInputChange(e) {
        if (e.target.name === 'title')
            this.setState({
                title: e.target.value
            });
        if (e.target.name === 'description')
            this.setState({
                description: e.target.value
            });
        if (e.target.name === 'instructions')
            this.setState({
                instructions: e.target.value
            });
        if (e.target.name === 'prereq')
            this.setState({
                prereq: e.target.value
            });
        if (e.target.name === 'prereq_description')
            this.setState({
                prereq_description: e.target.value
            });
        if (e.target.name === 'select_tag')
            this.setState({
                selected_tag: e.target.value
            });
    }

    addTag(event) {
        event.preventDefault()
        let newTagList = this.state.tags

        if (this.state.selected_tag && !newTagList.includes(this.state.selected_tag)) {
            newTagList.push(this.state.selected_tag)
        }
        this.setState({
            tags: newTagList
        });
    }

    removeTag(e) {
        var newTags = this.state.tags
        var index = newTags.indexOf(e.target.value);

        if (index > -1) {
            newTags.splice(index, 1);
        }

        this.setState({
            tags: newTags
        })
    }

    async addActivity(event) {
        event.preventDefault()

        let newTags = validateTags(this.state.tags);

        if (newTags.isValid) {
            //Add tags based on inputs
            let newActivity = {
                title: this.state.title,
                description: this.state.description,
                instructions: this.state.instructions,
                tags: this.state.tags,
                prereq: this.state.prereq,
                prereq_description: this.state.prereq_description
            }

            this.setState({
                newActivity: newActivity,
                error: ""
            });
            //Call Api POST
            try {
                let response = await Activities.createNewActivity(newActivity)
                let allTags = await Tags.getAllTags();
                if (response.status === 201) {
                    this.setState({
                        tags: allTags
                    })

                    toast.success(`Success: Activity succesfully added`);

                } else {
                    console.log("There was an error with the activity")
                }
            } catch (e) {
                console.log(e)
                console.log(e.response.data)
                this.setState({
                    error: e.response.data
                })
                toast.error(`Error: ${e.response.data}`);
            }
        } else {
            this.setState({
                error: newTags.errors
            })
            toast.error(`Error: ${newTags.errors}`);
        }
    }


    render() {


        return (
            <div className="AdminNewPage">
                <h1>AdminNewPage</h1>

                <NavLink to="/decide-admin">Go to admin Home</NavLink>

                <form onSubmit={this.addActivity} >
                    {this.state.error && <p>Error:{this.state.error}</p>}
                    <div className="titleDiv">
                        <label htmlFor="title">Title</label>
                        <input onChange={this.onInputChange} name="title" value={this.state.title} />
                    </div>
                    <div className="descriptionDiv">
                        <label htmlFor="description">description</label>
                        <textarea onChange={this.onInputChange} name="description" value={this.state.description} />
                    </div>
                    <div className="intructionsDiv">
                        <label htmlFor="instructions">instructions</label>
                        <textarea onChange={this.onInputChange} name="instructions" value={this.state.instructions} />
                    </div>
                    <div className="prereqDiv">
                        <label htmlFor="prereq">prereq</label>
                        <textarea onChange={this.onInputChange} name="prereq" value={this.state.prereq} />
                    </div>
                    <div className="prereq_descriptionDiv">
                        <label htmlFor="prereq_description">prereq description</label>
                        <textarea onChange={this.onInputChange} name="prereq_description" value={this.state.prereq_description} />
                    </div>
                    <div className="tagSelectDiv">
                        <select name="select_tag" onChange={this.onInputChange} value={this.state.selected_tag}>
                            <option value="">None</option>
                            {this.state.allTags.map(tag => {
                                return <option key={tag} value={tag}>{tag}</option>
                            })}

                        </select>
                        <button onClick={this.addTag}>Add Tag</button>
                    </div>

                    <div className="tagsToAddList">
                        <h5>New tags to be added</h5>
                        <ul>{this.state.tags.map(tag => { return <li key={tag}  ><button onClick={this.removeTag} value={tag}>{tag}</button></li> })}
                        </ul>
                    </div>

                    <button className="btn btn-primary" type='submit'>Create</button>
                    <NavLink to="/decide-admin">Go to admin Home</NavLink>
                </form>


                {/* <div><h5>New Activity</h5>
                    <ul>
                        <li>title: {this.state.newActivity.title}</li>
                        <li>description: {this.state.newActivity.description}</li>
                        <li>instructions: {this.state.newActivity.instructions}</li>
                        <li>tags:<ul>{this.state.newActivity.tags.map(tag => { return <li key={tag}>{tag}</li> })}
                        </ul> </li>
                    </ul>

                </div> */}
                <ToastContainer />
            </div>
        )
    }
}

export default AdminNewPage
