module.exports = (tags)=>{
    let errors=""
    let valid=true

    if(!tags.includes('leave') && !tags.includes('stay')){
        errors+=": Leave or Stay required ";
        valid=false;
    }

    if(!tags.includes('outside') && !tags.includes('inside')){
        errors+=": Outside or Inside required ";
        valid=false;
    }

    if(!tags.includes('tenPlusPeople') && !tags.includes('9')  && !tags.includes('8')
    && !tags.includes('7') && !tags.includes('6') && !tags.includes('5') && !tags.includes('4')
    && !tags.includes('3') && !tags.includes('2') && !tags.includes('1')){
        errors+=": tenPlusPeople or Another number of people required ";
        valid=false;
    }

    if(!tags.includes('30minutesOrLess') && !tags.includes('1hourOrLess') 
    && !tags.includes('2hoursOrLess') && !tags.includes('2hoursOrMore')){
        errors+=": Some Amount of Time is required ";
        valid=false;
    }

    if(!tags.includes('free') && !tags.includes('15dollarsOrLess') 
    && !tags.includes('50dollarsOrLess') && !tags.includes('50dollarsOrMore')){
        errors+=": At least one cost tag is required ";
        valid=false;
    }

    if(tags.includes('outside') && !tags.includes('springOutside') && !tags.includes('fallOutside') 
    && !tags.includes('summerOutside') && !tags.includes('winterOutside')){
        errors+=": At least one outside season tag is required ";
        valid=false;
    }

    if(tags.includes('outside') && !tags.includes('snowyOutside') && !tags.includes('coldNoSnowOutside') 
    && !tags.includes('warmOutside') && !tags.includes('hotOutside') && !tags.includes('rainyOutside')){
        errors+=": At least one outside weather tag is required ";
        valid=false;
    }

    

    return {isValid:valid, errors}
}