import React, { Component } from 'react'
import Activities from '../../api/activities';

export class admin extends Component {
    constructor(props) {
        super(props)
    
        this.state = {
             activityList:[],
             loading:false
        }
        
        this.btnClick=this.btnClick.bind(this);
    }

    componentDidMount(){
        console.log("Current Route:", this.props.location.pathname)
        if(this.props.location.pathname==="/decide-admin/list"){
            this.btnClick()
        }
    }

    async btnClick(){
        this.setState({
            loading:true
        });
        let newlist=[]
        
        let activities = await Activities.getAllActivities();
        // console.log("activities:", activities);
        if(activities){
            for(let i =0; i<activities.length;i++){
                newlist.push(activities[i]);
                // console.log(activities[i]);
            }
        }else{
            newlist.push({"title":"Error","description":"Something Append with api call"})
        }


        this.setState({
            activityList:newlist,
            loading:false
        });
    }

    //onclick of submit button,
    //onchange of values goes into local state values and then when submited we will send a request with info.

    render() {
        return (
            <div>
                <h1>This is the AdminPage</h1>
                <button onClick={this.btnClick}>List activities</button>
                <button><a href="/decide-admin/new/">Add new activities</a></button>
                <button onClick={()=>{ localStorage.clear(); sessionStorage.setItem("quizEnded", "False") }}><a href="/quiz/">Take The Quiz!</a></button>
                {/* <NavLink
                                to="quiz/"
                                onClick={()=>{ localStorage.clear(); sessionStorage.setItem("quizEnded", "False") }}
                            >Take The Quiz!</NavLink> */}
                {this.state.loading && <h2>Loading...</h2>}
                <ul>
                    {this.state.activityList.map((activity)=>
                    
                    <li key={activity._id}><div>
                    
                    <h4> <a href={`/decide-admin/edit/${activity._id}`}>{activity.title}</a> : {activity.description}</h4>
                   </div></li>)
                    }
                </ul>
         
            </div>
        )
    }
}

export default admin
