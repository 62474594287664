import React, { Component } from 'react'
import Activities from '../../api/activities';
import { NavLink} from 'react-router-dom';

export class Activity extends Component {
    constructor(props) {
        super(props)

        this.state = {
            title: "",
            description: "",
            instructions: "",
            tags: [],
            error: ""

        }

    }

    //LifeCycle method
    async componentWillMount() {
        try {
            let activity = await Activities.getActivityById(this.props.match.params.id);
            let description = !activity.description.startsWith("{") ? activity.description : null
            let instructions = !activity.instructions.startsWith("{") ? activity.instructions : null
            this.setState({
                tags: activity.tags,
                title: activity.title,
                description: description,
                instructions: instructions,
            })
        } catch (e) {
            console.log("Error: ", e)
        }
    }

    render() {

        return (
            <div>
                <h1>Activity</h1>
                <ul>
                    <li>
                        {"Title: "+ this.state.title}
                    </li>
                    {this.state.description &&                     
                    <li>
                        {`Description: ${this.state.description}`}
                    </li>}
                    {this.state.instructions &&                     
                    <li>
                        {`Instructions: ${this.state.instructions}`}
                    </li>}
                </ul>
                <div className="CenterAll">
                    <button className="btn btn-primary">
                        <NavLink className="btn-link" to="/quiz/results/">Back to results</NavLink>
                    </button>
                </div>


            </div>
        )



    }
}

export default Activity
